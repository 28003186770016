import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';

import { m } from 'framer-motion';

import { FeedEntryFeedbackOps } from './hooks/useFeedEntryOps';
import useToast from './hooks/useToast';
import { useTouchPadHorizontal } from './hooks/useTouchPad';
import CaretRightIcon from './icon/CaretRightIcon';
import { useKeyPress } from './KeyPressContext';
import { FeedLink } from './SmartFeedLink';

const DEFAULT_SLIDE_RIGHT_DISTANCE = 140;
const DEFAULT_SLIDE_LEFT_DISTANCE = 250;

interface Pan {
  x: number;
  y: number;
}

const HoverTouchWheelMenu = (props: {
  entryId: string;
  navToFeed: (feed: api.feed.IFeedInfo) => void;
  selected: boolean;
  disableKb: boolean;
  disableKbSlide?: boolean;
  compact: boolean;
  onArchive?: () => void;
  feedbackOps: FeedEntryFeedbackOps;
  hideFeedbackActions?: boolean;
  className?: string;
  leftSwipeFeeds: api.feed.IFeedInfo[];
  maxWidth?: number;
  swipeSurface?: React.ReactNode;
  childrenClassName?: string;
  children: React.ReactNode;
}) => {
  const { setToast } = useToast();
  const [prevAction, setPrevAction] = React.useState<'like' | 'dislike' | null>(null);
  const [slideState, setSlideState] = React.useState<
    'slide-left' | 'slide-right' | 'fly-right' | 'closed' | 'closed-timeout'
  >('closed');
  const [isToggled, setIsToggled] = React.useState(false);
  const [archivePending, setArchivePending] = React.useState(false);
  const [jumpToFeedPending, setJumpToFeedPending] = React.useState(false);
  const initPan = (): Pan => ({ x: 0, y: 0 });
  const pan = React.useRef<Pan>(initPan());
  const touchStart = React.useRef<Pan | null>(null);
  const touchPrev = React.useRef<Pan | null>(null);

  const clampDistance = (dist: number, buffer: number, max?: number): number => {
    if (max !== undefined) {
      return Math.min(dist, max - buffer);
    } else {
      return dist;
    }
  };
  const slideRightDistance = clampDistance(DEFAULT_SLIDE_RIGHT_DISTANCE, 10, props.maxWidth);
  const slideLeftDistance = clampDistance(DEFAULT_SLIDE_LEFT_DISTANCE, 20, props.maxWidth);

  const isVerticalPan = React.useRef(false);

  const likeAction = () => {
    if (prevAction === 'like') {
      props.feedbackOps.undoFeedback();
      setToast({
        header: 'Removed preference',
      });
      setPrevAction(null);
    } else {
      props.feedbackOps.moreLikeThis();
      props.feedbackOps.moreLikeThisToast();
      setPrevAction('like');
    }
  };

  const dislikeAction = () => {
    if (prevAction === 'dislike') {
      props.feedbackOps.undoFeedback();
      setToast({
        header: 'Removed preference',
      });
      setPrevAction(null);
    } else {
      props.feedbackOps.lessLikeThis();
      props.feedbackOps.lessLikeThisToast();
      setPrevAction('dislike');
    }
  };

  const archiveAction = () => {
    if (props.onArchive) {
      setSlideState('fly-right');
      setTimeout(props.onArchive, 200);
      // Add a bounce back animation in case the entry does not disappear after
      // the `fly-right`. This happens for feeds that are showing archived
      // entries.
      setTimeout(() => setSlideState('closed'), 500);
    }
  };

  const panStartState = React.useRef('closed');

  const onPan = React.useCallback(
    (x: number, instantaneousSlideDirection: 'slide-left' | 'slide-right') => {
      if (isToggled) {
        if (slideState === 'slide-right') {
          if (x < 0 && archivePending) {
            // Swipe backwards to undo pending archiving
            setArchivePending(false);
          } else if (x < -50) {
            // Swipe backwards to close menu
            setArchivePending(false);
            setIsToggled(false);
            // The timeout is necessary to make it more difficult to swipe
            // directly from slide-left to slide-right rather than pausing
            // in the closed state.
            setSlideState('closed-timeout');
            setTimeout(() => {
              setSlideState('closed');
              pan.current.x = Math.sign(pan.current.x) * 2;
            }, 150);
          }
        } else if (slideState === 'slide-left') {
          if (x > 0 && jumpToFeedPending) {
            // Swipe backwards to undo jump-to-feed
            setJumpToFeedPending(false);
          } else if (x > 50) {
            // Swipe backwards to close menu
            setJumpToFeedPending(false);
            setIsToggled(false);
            // The timeout is necessary to make it more difficult to swipe
            // directly from slide-left to slide-right rather than pausing
            // in the closed state.
            setSlideState('closed-timeout');
            setTimeout(() => {
              setSlideState('closed');
              pan.current.x = Math.sign(pan.current.x) * 2;
            }, 150);
          }
        }
      } else if (slideState === 'closed') {
        if (x > 80) {
          setIsToggled(true);
          setSlideState('slide-right');
        } else if (x < -80) {
          setIsToggled(true);
          setSlideState('slide-left');
        }
      }
      if (x > 800 || (x > 400 && panStartState.current === 'slide-right')) {
        // Even if the user has swiped fairly far, if they reverse direction
        // before letting go, then cancel the archive.
        setArchivePending(instantaneousSlideDirection === 'slide-right');
      } else if (x < -800 || (x < -400 && panStartState.current === 'slide-left')) {
        setJumpToFeedPending(instantaneousSlideDirection === 'slide-left');
      }
    },
    [slideState, isToggled, archivePending, jumpToFeedPending],
  );

  const { onWheelEventHandler } = useTouchPadHorizontal(props.entryId, e => {
    if (isToggled) {
      if (slideState === 'slide-right') {
        if (e.direction === 'left') {
          if (archivePending) {
            // Swipe backwards to undo pending archiving
            setArchivePending(false);
          } else {
            // Swipe backwards to close menu
            setArchivePending(false);
            setIsToggled(false);
            setSlideState('closed');
          }
        } else if (e.direction === 'right' && props.onArchive) {
          if (archivePending) {
            archiveAction();
          } else {
            setArchivePending(true);
            setTimeout(() => setArchivePending(false), 200);
          }
        }
      } else if (slideState === 'slide-left') {
        if (e.direction === 'right') {
          if (jumpToFeedPending) {
            // Swipe backwards to undo jump-to-feed
            setJumpToFeedPending(false);
          } else {
            // Swipe backwards to close menu
            setJumpToFeedPending(false);
            setIsToggled(false);
            setSlideState('closed');
          }
        } else if (e.direction === 'left' && props.leftSwipeFeeds.length > 0) {
          // NOTE: Swipe-left to jump-to-feed has been removed for the time
          // being since it does not add an entry to the browser history. It's
          // believed its due to a browser limitation/restriction on onWheel
          // events adding to history. See the git-blame for this comment to
          // find the jump-to-feed implementation that existed prior.
        }
      }
    } else if (slideState === 'closed') {
      if (e.direction === 'left') {
        setIsToggled(true);
        setSlideState('slide-left');
      } else {
        setIsToggled(true);
        setSlideState('slide-right');
      }
    }
  });

  const onTouchMove = React.useCallback(
    (e: TouchEvent) => {
      if (e.targetTouches.length !== 1 || touchStart.current === null) {
        return;
      }
      const deltaX = e.targetTouches[0].screenX - touchStart.current.x;
      if (slideState === 'closed') {
        isVerticalPan.current =
          isVerticalPan.current || Math.abs(deltaX) < Math.abs(e.targetTouches[0].screenY - touchStart.current.y);
        if (isVerticalPan.current) {
          return;
        }
      }
      const isCaptured = Math.abs(deltaX) > 10;
      if (isCaptured) {
        e.preventDefault();
      }
      const instantaneousSlideDirection =
        touchPrev.current && e.targetTouches[0].screenX >= touchPrev.current.x ? 'slide-right' : 'slide-left';
      onPan(10 * deltaX, instantaneousSlideDirection);
      touchPrev.current = { x: e.targetTouches[0].screenX, y: e.targetTouches[0].screenY };
    },
    [slideState, onPan],
  );

  const onTouchEnd = React.useCallback(() => {
    isVerticalPan.current = false;
    if (archivePending && props.onArchive) {
      setSlideState('fly-right');
      setTimeout(props.onArchive, 200);
    } else if (jumpToFeedPending && props.leftSwipeFeeds.length > 0) {
      props.navToFeed(props.leftSwipeFeeds[0]);
    }
  }, [archivePending, jumpToFeedPending]);

  const horizScrollerParent = props.swipeSurface !== undefined;
  const fullChildRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (fullChildRef.current) {
      const wheelHandler = (e: WheelEvent) => {
        onWheelEventHandler(e, horizScrollerParent);
      };
      fullChildRef.current.addEventListener('wheel', wheelHandler, { passive: !horizScrollerParent });

      const touchStartHandler = (e: TouchEvent) => {
        if (e.targetTouches.length !== 1) {
          return;
        }
        panStartState.current = slideState;
        touchStart.current = {
          x: e.targetTouches[0].screenX,
          y: e.targetTouches[0].screenY,
        };
        touchPrev.current = null;
      };
      fullChildRef.current.addEventListener('touchstart', touchStartHandler, { passive: true });

      const touchMoveHandler = (e: TouchEvent) => {
        if (touchStart.current === null) {
          return;
        }
        onTouchMove(e);
      };
      fullChildRef.current.addEventListener('touchmove', touchMoveHandler, { passive: false });

      const touchEndHandler = () => {
        onTouchEnd();
        touchStart.current = null;
        touchPrev.current = null;
      };
      fullChildRef.current.addEventListener('touchend', touchEndHandler, { passive: true });

      return () => {
        if (fullChildRef.current) {
          fullChildRef.current.removeEventListener('wheel', wheelHandler);
          fullChildRef.current.removeEventListener('touchstart', touchStartHandler);
          fullChildRef.current.removeEventListener('touchmove', touchMoveHandler);
          fullChildRef.current.removeEventListener('touchend', touchEndHandler);
        }
      };
    }
  }, [fullChildRef.current, onPan, onTouchEnd, onTouchMove, onWheelEventHandler]);

  const swipeSurfaceRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (swipeSurfaceRef.current) {
      const wheelHandler = (e: WheelEvent) => {
        onWheelEventHandler(e, horizScrollerParent);
      };
      swipeSurfaceRef.current.addEventListener('wheel', wheelHandler, { passive: !horizScrollerParent });

      const touchStartHandler = (e: TouchEvent) => {
        if (e.targetTouches.length !== 1) {
          return;
        }
        panStartState.current = slideState;
        touchStart.current = {
          x: e.targetTouches[0].screenX,
          y: e.targetTouches[0].screenY,
        };
        touchPrev.current = null;
      };
      swipeSurfaceRef.current.addEventListener('touchstart', touchStartHandler, { passive: true });

      const touchMoveHandler = (e: TouchEvent) => {
        if (touchStart.current === null) {
          return;
        }
        if (!isVerticalPan.current) {
          e.preventDefault();
        }
        onTouchMove(e);
      };
      swipeSurfaceRef.current.addEventListener('touchmove', touchMoveHandler, { passive: false });

      const touchEndHandler = () => {
        onTouchEnd();
        touchStart.current = null;
        touchPrev.current = null;
      };
      swipeSurfaceRef.current.addEventListener('touchend', touchEndHandler, { passive: true });

      return () => {
        if (swipeSurfaceRef.current) {
          swipeSurfaceRef.current.removeEventListener('wheel', wheelHandler);
          swipeSurfaceRef.current.removeEventListener('touchstart', touchStartHandler);
          swipeSurfaceRef.current.removeEventListener('touchmove', touchMoveHandler);
          swipeSurfaceRef.current.removeEventListener('touchend', touchEndHandler);
        }
      };
    }
  }, [swipeSurfaceRef.current, onWheelEventHandler]);
  const leftPaneRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (leftPaneRef.current) {
      const wheelHandler = (e: WheelEvent) => {
        if (slideState === 'closed') {
          return;
        }
        onWheelEventHandler(e, horizScrollerParent);
      };
      leftPaneRef.current.addEventListener('wheel', wheelHandler, { passive: !horizScrollerParent });

      const touchStartHandler = (e: TouchEvent) => {
        if (e.targetTouches.length !== 1) {
          return;
        }
        panStartState.current = slideState;
        touchStart.current = {
          x: e.targetTouches[0].screenX,
          y: e.targetTouches[0].screenY,
        };
        touchPrev.current = null;
      };
      leftPaneRef.current.addEventListener('touchstart', touchStartHandler, { passive: true });

      const touchMoveHandler = (e: TouchEvent) => {
        if (touchStart.current === null) {
          return;
        }
        if (slideState === 'closed') {
          return;
        }
        if (!isVerticalPan.current) {
          e.preventDefault();
        }
        onTouchMove(e);
      };
      leftPaneRef.current.addEventListener('touchmove', touchMoveHandler, { passive: false });

      const touchEndHandler = () => {
        onTouchEnd();
        touchStart.current = null;
        touchPrev.current = null;
      };
      leftPaneRef.current.addEventListener('touchend', touchEndHandler, { passive: true });
      return () => {
        if (leftPaneRef.current) {
          leftPaneRef.current.removeEventListener('wheel', wheelHandler);
          leftPaneRef.current.removeEventListener('touchstart', touchStartHandler);
          leftPaneRef.current.removeEventListener('touchmove', touchMoveHandler);
          leftPaneRef.current.removeEventListener('touchend', touchEndHandler);
        }
      };
    }
  }, [leftPaneRef.current, onWheelEventHandler, props.swipeSurface, slideState]);
  const rightPaneRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (rightPaneRef.current) {
      const wheelHandler = (e: WheelEvent) => {
        if (slideState === 'closed') {
          return;
        }
        onWheelEventHandler(e, horizScrollerParent);
      };
      rightPaneRef.current.addEventListener('wheel', wheelHandler, { passive: !horizScrollerParent });

      const touchStartHandler = (e: TouchEvent) => {
        if (e.targetTouches.length !== 1) {
          return;
        }
        panStartState.current = slideState;
        e.stopPropagation();
        touchStart.current = {
          x: e.targetTouches[0].screenX,
          y: e.targetTouches[0].screenY,
        };
        touchPrev.current = null;
      };
      rightPaneRef.current.addEventListener('touchstart', touchStartHandler, { passive: true });

      const touchMoveHandler = (e: TouchEvent) => {
        if (touchStart.current === null) {
          return;
        }
        if (slideState === 'closed') {
          return;
        }
        if (!isVerticalPan.current) {
          e.preventDefault();
        }
        onTouchMove(e);
      };
      rightPaneRef.current.addEventListener('touchmove', touchMoveHandler, { passive: false });

      const touchEndHandler = () => {
        onTouchEnd();
        touchStart.current = null;
        touchPrev.current = null;
      };
      rightPaneRef.current.addEventListener('touchend', touchEndHandler, { passive: true });
      return () => {
        if (rightPaneRef.current) {
          rightPaneRef.current.removeEventListener('wheel', wheelHandler);
          rightPaneRef.current.removeEventListener('touchstart', touchStartHandler);
          rightPaneRef.current.removeEventListener('touchmove', touchMoveHandler);
          rightPaneRef.current.removeEventListener('touchend', touchEndHandler);
        }
      };
    }
  }, [rightPaneRef.current, onWheelEventHandler, props.swipeSurface, slideState]);

  const kbOpen = () => {
    if (slideState === 'slide-left' && props.leftSwipeFeeds.length > 0) {
      if (rightMenuKbIndex < props.leftSwipeFeeds.length) {
        props.navToFeed(props.leftSwipeFeeds[rightMenuKbIndex]);
      } else {
        props.navToFeed(props.leftSwipeFeeds[0]);
      }
    } else if (slideState === 'slide-right') {
      if (leftMenuKbIndex === 'archive' && props.onArchive) {
        archiveAction();
      } else if (leftMenuKbIndex === 'like') {
        likeAction();
      } else if (leftMenuKbIndex === 'dislike') {
        dislikeAction();
      }
    }
  };

  useKeyPress(
    'o',
    () => {
      // o: Open menu option
      kbOpen();
    },
    !props.selected || props.disableKb || slideState === 'closed',
  );

  useKeyPress(
    'Enter',
    () => {
      // Enter: Open menu option
      kbOpen();
    },
    !props.selected || props.disableKb || slideState === 'closed',
  );

  useKeyPress(
    props.disableKbSlide ? 'H' : ['H', 'h'],
    () => {
      // h: Slide left
      if (slideState === 'closed') {
        setSlideState('slide-left');
      } else if (slideState === 'slide-right') {
        setSlideState('closed');
      } else if (slideState === 'slide-left' && props.leftSwipeFeeds.length > 0) {
        kbOpen();
      }
    },
    !props.selected || props.disableKb,
  );

  useKeyPress(
    props.disableKbSlide ? 'L' : ['L', 'l'],
    () => {
      // l: Slide right
      if (slideState === 'closed') {
        setSlideState('slide-right');
      } else if (slideState === 'slide-left') {
        setSlideState('closed');
      } else if (slideState === 'slide-right') {
        kbOpen();
      }
    },
    !props.selected || props.disableKb,
  );

  const [rightMenuKbIndex, setRightMenuKbIndex] = React.useState(0);
  const [leftMenuKbIndex, setLeftMenuKbIndex] = React.useState<'archive' | 'like' | 'dislike'>('archive');

  useKeyPress(
    'p',
    () => {
      // p: Menu option up
      if (slideState === 'slide-left' && rightMenuKbIndex > 0) {
        setRightMenuKbIndex(rightMenuKbIndex - 1);
      } else if (slideState === 'slide-right') {
        if (leftMenuKbIndex === 'archive') {
          setLeftMenuKbIndex('like');
        } else if (leftMenuKbIndex === 'dislike') {
          setLeftMenuKbIndex('archive');
        }
      }
    },
    !props.selected || props.disableKb || slideState === 'closed',
  );

  useKeyPress(
    'n',
    () => {
      // n: Menu option down
      if (slideState === 'slide-left') {
        setRightMenuKbIndex(Math.min(rightMenuKbIndex + 1, props.leftSwipeFeeds.length - 1));
      } else if (slideState === 'slide-right') {
        if (leftMenuKbIndex === 'archive') {
          setLeftMenuKbIndex('dislike');
        } else if (leftMenuKbIndex === 'like') {
          setLeftMenuKbIndex('archive');
        }
      }
    },
    !props.selected || props.disableKb || slideState === 'closed',
  );

  // Archive key shortcut handled here for fly-away animation
  useKeyPress(
    'e',
    () => {
      // e: Archive
      archiveAction();
    },
    !props.selected,
  );
  // HACK: Unfortunately, there's a hard to debug issue where an onMouseEnter
  // event is immediately followed by an onMouseLeave event causing a "bounce"
  // when hovering over the left pane (issue is absent for right pane). This
  // hack performs a "debounce," deffering any leave event if it occurs soon
  // after an enter event.
  const slideRightTs = React.useRef<number | null>(null);
  const slideRightLeaveTimeoutId = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  return (
    <div className={clsx('tw-relative', props.className)}>
      <m.div
        ref={leftPaneRef}
        initial="closed"
        animate={slideState === 'slide-right' || slideState === 'fly-right' ? 'open' : 'closed'}
        variants={{
          closed: { opacity: 0, width: props.swipeSurface ? 20 : 20 },
          open: { opacity: 1, width: slideRightDistance },
        }}
        className={clsx(
          'tw-absolute tw-left-0 tw-top-0 tw-h-full tw-bg-transparent',
          'tw-flex tw-flex-col tw-justify-center',
        )}
        style={{ maxWidth: props.maxWidth }}
        onMouseEnter={
          props.swipeSurface === undefined
            ? () => {
                if (slideRightLeaveTimeoutId.current) {
                  clearTimeout(slideRightLeaveTimeoutId.current);
                  slideRightLeaveTimeoutId.current = null;
                }
                if (slideState === 'closed') {
                  slideRightTs.current = Date.now();
                  setSlideState('slide-right');
                }
              }
            : undefined
        }
        onMouseLeave={() => {
          if (!isToggled) {
            if (slideRightTs.current && Date.now() - slideRightTs.current < 50) {
              slideRightLeaveTimeoutId.current = setTimeout(() => setSlideState('closed'), 300);
            } else {
              setSlideState('closed');
            }
          }
        }}
      >
        {slideState === 'slide-right' ? (
          <div className={clsx('tw-pl-3', 'tw-flex tw-flex-col')}>
            {!props.hideFeedbackActions ? (
              <MenuButton
                highlight="green"
                compact={props.compact}
                active={prevAction === 'like'}
                kbActive={leftMenuKbIndex === 'like'}
                className="tw-mr-2"
                onClick={likeAction}
              >
                More like this
              </MenuButton>
            ) : null}
            <MenuButton
              compact={props.compact}
              active={archivePending}
              kbActive={leftMenuKbIndex === 'archive'}
              className="tw-mr-2"
              onClick={archiveAction}
            >
              Archive
            </MenuButton>
            {!props.hideFeedbackActions ? (
              <MenuButton
                highlight="red"
                compact={props.compact}
                active={prevAction === 'dislike'}
                kbActive={leftMenuKbIndex === 'dislike'}
                className="tw-mr-2"
                onClick={dislikeAction}
              >
                Less like this
              </MenuButton>
            ) : null}
          </div>
        ) : null}
        {props.swipeSurface ? (
          <div
            className="tw-absolute tw-bottom-0 tw-left-0 tw-h-10 tw-w-[20px]"
            onMouseEnter={
              props.swipeSurface !== undefined
                ? () => {
                    if (slideRightLeaveTimeoutId.current) {
                      clearTimeout(slideRightLeaveTimeoutId.current);
                      slideRightLeaveTimeoutId.current = null;
                    }
                    if (slideState === 'closed') {
                      slideRightTs.current = Date.now();
                      setSlideState('slide-right');
                    }
                  }
                : undefined
            }
          ></div>
        ) : null}
      </m.div>
      {props.swipeSurface ? (
        <m.div
          className={clsx('tw-flex tw-flex-col', props.childrenClassName)}
          initial="closed"
          animate={
            slideState === 'slide-right'
              ? 'slideRight'
              : slideState === 'slide-left'
                ? 'slideLeft'
                : slideState === 'fly-right'
                  ? 'flyRight'
                  : 'closed'
          }
          variants={{
            closed: { x: 0 },
            flyRight: {
              transition: {
                duration: 0.3,
              },
              x: window.innerWidth,
            },
            slideLeft: {
              x: -slideLeftDistance,
            },
            slideRight: {
              x: slideRightDistance,
            },
          }}
        >
          {props.children}
          <div ref={swipeSurfaceRef}>{props.swipeSurface}</div>
        </m.div>
      ) : (
        <m.div
          ref={fullChildRef}
          initial="closed"
          animate={
            slideState === 'slide-right'
              ? 'slideRight'
              : slideState === 'slide-left'
                ? 'slideLeft'
                : slideState === 'fly-right'
                  ? 'flyRight'
                  : 'closed'
          }
          variants={{
            closed: { x: 0 },
            flyRight: {
              transition: {
                duration: 0.3,
              },
              x: window.innerWidth,
            },
            slideLeft: {
              // Opacity is to make long side-pane-menu-btn-text visible.
              opacity: 0.8,
              x: -slideLeftDistance,
            },
            slideRight: {
              // Opacity is to make long side-pane-menu-btn-text visible.
              opacity: 0.8,
              x: slideRightDistance,
            },
          }}
        >
          {props.children}
        </m.div>
      )}
      <m.div
        ref={rightPaneRef}
        initial="closed"
        animate={slideState === 'slide-left' ? 'open' : 'closed'}
        variants={{
          closed: { opacity: 0, width: props.swipeSurface ? 20 : 20 },
          open: { opacity: 1, width: slideLeftDistance },
        }}
        className={clsx('tw-z-10 tw-absolute tw-right-0 tw-top-0 tw-h-full', 'tw-flex tw-flex-col tw-justify-center')}
        style={{ maxWidth: props.maxWidth }}
        onMouseEnter={
          props.swipeSurface === undefined
            ? () => {
                if (slideState === 'closed') {
                  setSlideState('slide-left');
                }
              }
            : undefined
        }
        onMouseLeave={() => {
          if (!isToggled) {
            setSlideState('closed');
          }
        }}
      >
        {slideState === 'slide-left'
          ? props.leftSwipeFeeds.map((leftSwipeFeed, index) => (
              <FeedLink
                key={leftSwipeFeed.feed_id}
                navToFeed={props.navToFeed}
                feed={leftSwipeFeed}
                className="tw-flex tw-flex-row-reverse tw-text-primary hover:tw-no-underline"
              >
                <MenuButton
                  active={index === 0 && jumpToFeedPending}
                  kbActive={rightMenuKbIndex === index}
                  compact={props.compact}
                  className="tw-mx-2"
                  reverse
                >
                  <span className="tw-max-w-[190px] tw-overflow-hidden">
                    {cfe.ApiHelpers.getFeedShortName(leftSwipeFeed)}
                  </span>
                  <FeedTypeBadge feed={leftSwipeFeed} />
                </MenuButton>
              </FeedLink>
            ))
          : null}
        {props.swipeSurface ? (
          <div
            className="tw-absolute tw-bottom-0 tw-right-0 tw-h-10 tw-w-[20px]"
            onMouseEnter={
              props.swipeSurface !== undefined
                ? () => {
                    if (slideState === 'closed') {
                      setSlideState('slide-left');
                    }
                  }
                : undefined
            }
          ></div>
        ) : null}
      </m.div>
    </div>
  );
};

const FeedTypeBadge = (props: { feed: api.feed.IFeedInfo }) =>
  cfe.MatterHelpers.getFeedTypeLetter(props.feed) ? (
    <div className="tw-ml-1 tw-font-mono tw-font-bold tw-text-muted tw-text-xs tw-w-4 tw-text-center tw-bg-primary tw-rounded">
      <span className="tw-relative tw-top-0.5">{cfe.MatterHelpers.getFeedTypeLetter(props.feed)}</span>
    </div>
  ) : null;

const MenuButton = (props: {
  highlight?: 'red' | 'green';
  compact: boolean;
  className?: string;
  active: boolean;
  kbActive: boolean;
  reverse?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}) => (
  <div
    role="button"
    onClick={props.onClick}
    className={clsx(
      'tw-flex',
      props.reverse ? 'tw-flex-row-reverse' : null,
      'tw-select-none tw-cursor-pointer',
      'tw-rounded-md tw-border tw-border-2',
      !props.active ? 'tw-bg-transparent tw-border-transparent' : null,
      props.active && props.highlight === 'green'
        ? 'tw-bg-[#00ff0033] tw-border-[#16a34a] dark:tw-bg-[#00ff0066] dark:tw-border-[#00ff00]'
        : null,
      props.active && props.highlight === 'red' ? 'tw-bg-[#ff000066] tw-border-[#ff0000]' : null,
      props.active && props.highlight === undefined
        ? 'tw-bg-purple-300 dark:tw-bg-[#3b0764] tw-border-perpul-light'
        : null,
      props.highlight === 'green'
        ? 'hover:tw-bg-[#00ff0033] hover:tw-border-[#16a34a] dark:hover:tw-bg-[#00ff0066] dark:hover:tw-border-[#00ff00]'
        : props.highlight === 'red'
          ? 'hover:tw-bg-[#ff000066] hover:tw-border-[#ff0000]'
          : 'hover:tw-bg-purple-300  dark:hover:tw-bg-[#3b0764] hover:tw-border-perpul-light',
      'tw-px-1',
      props.compact ? null : 'tw-py-0.5',
      'tw-whitespace-nowrap',
      props.compact ? 'tw-text-xs' : 'tw-text-sm',
      'hover:tw-font-semibold',
      props.active ? 'tw-font-semibold' : null,
      props.className,
    )}
  >
    <div className={clsx('tw-flex tw-items-center')}>
      <div className="tw-min-w-[0.7rem]">{props.kbActive ? <CaretRightIcon offsetUp size="0.6rem" /> : null}</div>
      {props.children}
    </div>
  </div>
);

export default HoverTouchWheelMenu;
