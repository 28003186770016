import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PlayerStates from 'youtube-player/dist/constants/PlayerStates';
import { YouTubePlayer } from 'youtube-player/dist/types';

import * as store from '../state/store';

import useKeyDownEvent from './hooks/useKeyDownEvent';
import ExploreIcon from './icon/ExploreIcon';
import { useKeyPress } from './KeyPressContext';
import Button from './lib/Button';
import Spinner from './lib/Spinner';

const YouTube = React.lazy(() => import('react-youtube'));

const YouTubeCustomPlayer = (props: {
  ytVideoId: string;
  recordStoryOpen: () => void;
  startProgress?: number;
  updateProgress?: (progress: number) => void;
  portrait?: boolean;
  forceNoAutoPlay?: boolean;
  gutterClassName?: string;
  focusPlay?: boolean;
  onEnd?: () => void;
  maxHeightShort?: boolean;
  mainEntry?: api.feed.IFeedEntryPair;
}) => {
  const autoPlayMediaGlobal =
    useSelector<store.IAppState, boolean>(state => state.autoPlayMedia) && !props.forceNoAutoPlay;
  const autoPlayMedia = React.useRef(autoPlayMediaGlobal);

  const [ytPlayer, setYtPlayer] = useState<YouTubePlayer | null>(null);
  const [playerState, setPlayerState] = useState<PlayerStates | null>(null);

  const startProgressUsed = React.useRef(false);

  useEffect(() => {
    if (autoPlayMediaGlobal) {
      autoPlayMedia.current = true;
    }
  }, [autoPlayMediaGlobal]);

  useEffect(() => {
    if (ytPlayer && playerState === PlayerStates.VIDEO_CUED && autoPlayMedia.current) {
      ytPlayer.playVideo();
    }
  }, [playerState]);

  // Reset internal state when YT video changes while re-using component.
  useEffect(() => {
    // UNSTARTED is our best indicator that a new video is loading.
    if (playerState === PlayerStates.UNSTARTED) {
      playerCurTimeLastRecorded.current = null;
      playerProgress.current = null;
      recordedUrlOpen.current = false;
      startProgressUsed.current = false;
    }
  }, [props.ytVideoId, playerState]);

  // For efficiency, only record that the URL was opened once rather than
  // relying on the server-side to reject duplicates.
  const recordedUrlOpen = React.useRef(false);
  useEffect(() => {
    if (!recordedUrlOpen.current && playerState === PlayerStates.PLAYING) {
      const visitedId = setTimeout(() => {
        recordedUrlOpen.current = true;
        props.recordStoryOpen();
      }, 5000);
      return () => {
        clearTimeout(visitedId);
      };
    }
  }, [props.ytVideoId, playerState]);

  const playerProgress = React.useRef<[number, number] | null>(null);
  const playerCurTimeLastRecorded = React.useRef<number | null>(null);
  useEffect(() => {
    if (!ytPlayer) {
      return;
    }
    const internalProgressUpdateIntervalId = setInterval(() => {
      const curTime = ytPlayer.getCurrentTime();
      const duration = ytPlayer.getDuration();
      if (curTime > 0 && duration > 0) {
        playerProgress.current = [curTime, duration];
        if (props.updateProgress && recordedUrlOpen.current) {
          const progress = (playerProgress.current[0] / playerProgress.current[1]) * 100;
          if (
            playerCurTimeLastRecorded.current === null ||
            Math.abs(curTime - playerCurTimeLastRecorded.current) >= 10
          ) {
            props.updateProgress(progress);
            playerCurTimeLastRecorded.current = curTime;
          }
        }
      }
    }, 5000);
    return () => {
      clearInterval(internalProgressUpdateIntervalId);
    };
  }, [ytPlayer]);

  // Do a final update of progress when video player is closing or changing videos.
  useEffect(() => {
    return () => {
      if (props.updateProgress && recordedUrlOpen.current && playerProgress.current) {
        const progress = (playerProgress.current[0] / playerProgress.current[1]) * 100;
        props.updateProgress(progress);
      }
    };
  }, [ytPlayer, props.ytVideoId]);

  // Jump to start point when video first loads.
  useEffect(() => {
    if (ytPlayer && playerState === PlayerStates.PLAYING) {
      if (props.startProgress && !startProgressUsed.current) {
        const duration = ytPlayer.getDuration();
        if (duration > 0) {
          const startTs = (duration * props.startProgress) / 100;
          ytPlayer.seekTo(startTs, true);
          startProgressUsed.current = true;
        }
      }
    }
  }, [ytPlayer, props.ytVideoId, playerState]);

  useEffect(() => {
    if (ytPlayer && props.focusPlay !== undefined) {
      if (props.focusPlay) {
        ytPlayer.playVideo();
      } else {
        ytPlayer.pauseVideo();
      }
    }
  }, [ytPlayer, props.focusPlay]);

  useKeyPress(' ', (_, e) => {
    // space: Play or pause video
    if (ytPlayer) {
      if (ytPlayer.getPlayerState() === 1) {
        ytPlayer.pauseVideo();
      } else {
        ytPlayer.playVideo();
      }
      e.preventDefault();
    }
  });

  useKeyDownEvent((code, e) => {
    if (!ytPlayer) {
      return;
    }
    if (code === 'ArrowLeft' && e.shiftKey) {
      // shift + left arrow: decrease playback rate
      ytPlayer.setPlaybackRate(ytPlayer.getPlaybackRate() - 0.25);
      e.preventDefault();
    } else if (code === 'ArrowLeft') {
      // left arrow: go 5s back in time
      ytPlayer.seekTo(ytPlayer.getCurrentTime() - 5, true);
      e.preventDefault();
    } else if (code === 'ArrowRight' && e.shiftKey) {
      // shift + right arrow: increase playback rate
      ytPlayer.setPlaybackRate(ytPlayer.getPlaybackRate() + 0.25);
      e.preventDefault();
    } else if (code === 'ArrowRight') {
      // right arrow: go 5s forward in time
      ytPlayer.seekTo(ytPlayer.getCurrentTime() + 5, true);
      e.preventDefault();
    }
  });

  return (
    <React.Suspense fallback={<Spinner lg />}>
      <div
        className={clsx(
          'tw-relative',
          props.portrait ? 'tw-aspect-[9/16] tw-youtube-container-portrait' : 'tw-aspect-video tw-w-full',
          props.maxHeightShort ? 'tw-max-h-80' : null,
          // HACK: Can't get player to size correctly with buttons. See below.
          props.mainEntry ? 'tw-mb-12' : null,
        )}
      >
        <YouTube
          videoId={props.ytVideoId}
          opts={{
            height: '480',
            host: 'https://www.youtube-nocookie.com',
            width: '853',
          }}
          containerClassName={
            '[&_iframe]:tw-w-full [&_iframe]:tw-h-full [&_iframe]:tw-absolute [&_iframe]:tw-top-0 [&_iframe]:tw-left-0'
          }
          onStateChange={e => {
            setPlayerState(e.target.getPlayerState());
          }}
          onEnd={() => {
            if (props.onEnd) {
              props.onEnd();
            }
          }}
          onReady={e => {
            setYtPlayer(e.target);
            // When the YTPlayer is first ready, onStateChange() isn't
            // called so it can't be relied on to playVideo().
            if (autoPlayMedia.current) {
              e.target.playVideo();
            }
          }}
        />
        {props.mainEntry ? (
          // HACK: Can't get player to size correctly with buttons so instead
          // they're injected using absolute positioning and negative margins.
          <div className={clsx('tw-absolute tw-w-full tw-bottom-0 tw-left-0 tw-right-0 -tw-mb-12')}>
            <div className={clsx('tw-flex tw-flex-row-reverse', props.gutterClassName)}>
              {props.mainEntry ? (
                <Button
                  className="tw-rounded-full"
                  variant="secondary"
                  onClick={e => {
                    e.stopPropagation();
                    if (!props.mainEntry) {
                      return;
                    }
                    window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.mainEntry.entry.entry_id), '_blank');
                  }}
                  onAuxClick={e => {
                    e.stopPropagation();
                    if (!props.mainEntry) {
                      return;
                    }
                    window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.mainEntry.entry.entry_id), '_blank');
                  }}
                >
                  <ExploreIcon size="1.1rem" />
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </React.Suspense>
  );
};

export default YouTubeCustomPlayer;
