"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseClient = void 0;
class BaseClient {
    /**
     * Agents can see the capabilities of other agents.
     *
     */
    accountAgentGetPerms(arg) {
        return this.request('account', 'agent/get_perms', arg, 'user');
    }
    /**
     * FSM staff users can make other users agents. This can be used to onboard
     * new agents/editors using non-superego emails.
     *
     */
    accountAgentGrant(arg) {
        return this.request('account', 'agent/grant', arg, 'user');
    }
    /**
     * FSM staff users can revoke another user's agent status. This can be used
     * to offboard agents/editors.
     *
     */
    accountAgentRevoke(arg) {
        return this.request('account', 'agent/revoke', arg, 'user');
    }
    /**
     * Create associated creator account.
     *
     */
    accountBeACreator(arg) {
        return this.request('account', 'be_a_creator', arg, 'user');
    }
    /**
     * Change the calling user's username.
     *
     */
    accountChangeUsername(arg) {
        return this.request('account', 'change_username', arg, 'user');
    }
    accountCommunicationPreferenceGet(arg) {
        return this.request('account', 'communication_preference/get', arg, 'user');
    }
    accountCommunicationPreferenceSet(arg) {
        return this.request('account', 'communication_preference/set', arg, 'user');
    }
    /**
     * To unlink a token from a device to disable push notifications.
     *
     */
    accountDeregisterPushNotifDeviceToken(arg) {
        return this.request('account', 'deregister_push_notif_device_token', arg, 'user');
    }
    /**
     * Returns a token that can be used with /login_by_token (www host) to
     * obtain a logged-in web session.
     *
     */
    accountExplodingTokenForWebSession(arg) {
        return this.request('account', 'exploding_token_for_web_session', arg, 'user');
    }
    /**
     * Initiates a forgot password flow. The code to be passed into
     * resetPassword() is sent via email.
     *
     */
    accountForgotPassword(arg) {
        return this.request('account', 'forgot_password', arg, 'noauth');
    }
    /**
     * Retrieves information about the Chrome extension.
     *
     */
    accountGetChromeExtensionInfo(arg) {
        return this.request('account', 'get_chrome_extension_info', arg, 'user_optional');
    }
    accountNotificationDevicePolicyGet(arg) {
        return this.request('account', 'notification_device_policy/get', arg, 'user');
    }
    accountNotificationDevicePolicySet(arg) {
        return this.request('account', 'notification_device_policy/set', arg, 'user');
    }
    /**
     * Triggers our internal post-onboarding actions. Post-onboarding actions
     * should be unnecessary for the proper functioning of a user account.
     *
     */
    accountOnboardingDone(arg) {
        return this.request('account', 'onboarding/done', arg, 'user');
    }
    /**
     * Create a new user account.
     *
     */
    accountRegisterByEmail(arg) {
        return this.request('account', 'register_by_email', arg, 'noauth');
    }
    /**
     * For devices to report the token to use to push notifications to them.
     *
     */
    accountRegisterPushNotifDeviceToken(arg) {
        return this.request('account', 'register_push_notif_device_token', arg, 'user');
    }
    accountResetPassword(arg) {
        return this.request('account', 'reset_password', arg, 'noauth');
    }
    /**
     * Staff users can restrict the capabilities of users. This is primarily for
     * abuse prevention.
     *
     */
    accountRestrict(arg) {
        return this.request('account', 'restrict', arg, 'user');
    }
    /**
     * Deletes a user's own account permanently.
     *
     */
    accountSeppuku(arg) {
        return this.request('account', 'seppuku', arg, 'user');
    }
    /**
     * Create an OAuth2 token from an Apple Sign In auth code.
     *
     */
    accountTokenFromAppleSignIn(arg) {
        return this.request('account', 'token_from_apple_sign_in', arg, 'noauth');
    }
    /**
     * Create an OAuth2 token from a Google Sign In token.
     *
     */
    accountTokenFromGoogleSignin(arg) {
        return this.request('account', 'token_from_google_signin', arg, 'noauth');
    }
    /**
     * Create an OAuth2 token from an e-mail & password login.
     *
     */
    accountTokenFromLogin(arg) {
        return this.request('account', 'token_from_login', arg, 'noauth');
    }
    /**
     * Create an OAuth2 token from a logged-in web session. This is used by the
     * account page to request a token for the chrome extension when the 'auto
     * sign in' feature is used. Unfortunately, it is dangerous as any arbitrary
     * JS running on our account page can now issue tokens.
     *
     */
    accountTokenFromWebSession(arg) {
        return this.request('account', 'token_from_web_session', arg, 'user');
    }
    /**
     * Verifies a registered e-mail address. Primarily for testing.
     *
     */
    accountVerifyEmail(arg) {
        return this.request('account', 'verify_email', arg, 'noauth');
    }
    accountWebSessionAssume(arg) {
        return this.request('account', 'web_session/assume', arg, 'user');
    }
    accountWebSessionLogout(arg) {
        return this.request('account', 'web_session/logout', arg, 'user_optional');
    }
    accountWebSessionRevert(arg) {
        return this.request('account', 'web_session/revert', arg, 'user');
    }
    /**
     * For use with a browser. HTTP Response sets a session cookie.
     *
     */
    accountWebSessionFromGoogleSignin(arg) {
        return this.request('account', 'web_session_from_google_signin', arg, 'noauth');
    }
    /**
     * For use with a browser. HTTP Response sets a session cookie.
     *
     */
    accountWebSessionFromLogin(arg) {
        return this.request('account', 'web_session_from_login', arg, 'noauth');
    }
    /**
     * Create a checkout session.
     *
     */
    billingStripeCreateCheckoutSession(arg) {
        return this.request('billing', 'stripe/create_checkout_session', arg, 'user');
    }
    /**
     * Ends the user's subscription.
     *
     */
    billingSubscriptionEnd(arg) {
        return this.request('billing', 'subscription/end', arg, 'user');
    }
    /**
     * Get the current user's subscription status. Deprecated: See
     * subscriptionGetStatus2().
     *
     */
    billingSubscriptionGetStatus(arg) {
        return this.request('billing', 'subscription/get_status', arg, 'user');
    }
    /**
     * Get the current user's subscription status.
     *
     */
    billingSubscriptionGetStatus2(arg) {
        return this.request('billing', 'subscription/get_status2', arg, 'user');
    }
    /**
     * Returns all the tipping period statements for a user.
     *
     */
    billingTippingPeriodStatementAll(arg) {
        return this.request('billing', 'tipping_period_statement/all', arg, 'user');
    }
    billingTippingPeriodStatementCurrent(arg) {
        return this.request('billing', 'tipping_period_statement/current', arg, 'user');
    }
    /**
     * Creates a new creator that isn't associated with any user.
     *
     */
    creatorCreate(arg) {
        return this.request('creator', 'create', arg, 'user');
    }
    creatorDomainAttributionAdd(arg) {
        return this.request('creator', 'domain_attribution/add', arg, 'user');
    }
    creatorDomainAttributionAll(arg) {
        return this.request('creator', 'domain_attribution/all', arg, 'user');
    }
    /**
     * Get information about a creator.
     *
     */
    creatorGet(arg) {
        return this.request('creator', 'get', arg, 'user');
    }
    /**
     * Associates a user account with a creator. If the user is already
     * associated with a creator, removes the existing association. If the
     * creator is already associated with a user, errors.
     *
     */
    creatorPairUserAndCreator(arg) {
        return this.request('creator', 'pair_user_and_creator', arg, 'user');
    }
    /**
     * Search for creators in the system.
     *
     */
    creatorSearch(arg) {
        return this.request('creator', 'search', arg, 'user');
    }
    /**
     * Add a tag to a creator.
     *
     */
    creatorTagAdd(arg) {
        return this.request('creator', 'tag/add', arg, 'user');
    }
    /**
     * Remove a tag from a creator.
     *
     */
    creatorTagRemove(arg) {
        return this.request('creator', 'tag/remove', arg, 'user');
    }
    creatorUrlPrefixAttributionAdd(arg) {
        return this.request('creator', 'url_prefix_attribution/add', arg, 'user');
    }
    creatorUrlPrefixAttributionAll(arg) {
        return this.request('creator', 'url_prefix_attribution/all', arg, 'user');
    }
    creatorYoutubeChannelAttributionAdd(arg) {
        return this.request('creator', 'youtube_channel_attribution/add', arg, 'user');
    }
    creatorYoutubeChannelAttributionAll(arg) {
        return this.request('creator', 'youtube_channel_attribution/all', arg, 'user');
    }
    creatorAccountAcceptTos(arg) {
        return this.request('creator_account', 'accept_tos', arg, 'user');
    }
    creatorAccountAddWorkToWorksFeed(arg) {
        return this.request('creator_account', 'add_work_to_works_feed', arg, 'user');
    }
    /**
     * Get a list of all URLs that a user is tracked as a creator of.
     *
     */
    creatorAccountCreatorOfAll(arg) {
        return this.request('creator_account', 'creator_of/all', arg, 'user');
    }
    /**
     * Get a list of all URLs that a user is tracked as a creator of.
     *
     */
    creatorAccountCreatorOfGet(arg) {
        return this.request('creator_account', 'creator_of/get', arg, 'user');
    }
    creatorAccountDemoteFromPrimaryWorks(arg) {
        return this.request('creator_account', 'demote_from_primary_works', arg, 'user');
    }
    /**
     * Get revenue on a single day.
     *
     */
    creatorAccountGetRevenueForDay(arg) {
        return this.request('creator_account', 'get_revenue_for_day', arg, 'user');
    }
    /**
     * Get revenue for the previous seven days, not including today.
     *
     */
    creatorAccountGetRevenueForPastWeek(arg) {
        return this.request('creator_account', 'get_revenue_for_past_week', arg, 'user');
    }
    creatorAccountGetStatus(arg) {
        return this.request('creator_account', 'get_status', arg, 'user');
    }
    creatorAccountGetTos(arg) {
        return this.request('creator_account', 'get_tos', arg, 'user');
    }
    creatorAccountPromoteToPrimaryWorks(arg) {
        return this.request('creator_account', 'promote_to_primary_works', arg, 'user');
    }
    creatorAccountRemoveWorkFromWorksFeed(arg) {
        return this.request('creator_account', 'remove_work_from_works_feed', arg, 'user');
    }
    /**
     * Change the position the feed is returned by iter() and iterNext().
     *
     */
    feedChangePos(arg) {
        return this.request('feed', 'change_pos', arg, 'user');
    }
    /**
     * Copies entries and followers from one hashtag/topic feed to another. This
     * is useful for migrating from one hashtag to another though it does not
     * copy over everything (e.g. user notification preferences and feed recs).
     * It also does not delete the source feed so it needs to be marked
     * deprecated some other way.
     *
     */
    feedCopyHashtag(arg) {
        return this.request('feed', 'copy_hashtag', arg, 'user');
    }
    /**
     * Create a new feed for a user.
     *
     */
    feedCreate(arg) {
        return this.request('feed', 'create', arg, 'user');
    }
    /**
     * Create a new hashtag feed that's empty.
     *
     */
    feedCreateHashtag(arg) {
        return this.request('feed', 'create_hashtag', arg, 'user');
    }
    /**
     * Create a new stream feed for a user.
     *
     */
    feedCreateStream(arg) {
        return this.request('feed', 'create_stream', arg, 'user');
    }
    feedDefaultEphemeralityGet(arg) {
        return this.request('feed', 'default_ephemerality/get', arg, 'user');
    }
    feedDefaultEphemeralitySet(arg) {
        return this.request('feed', 'default_ephemerality/set', arg, 'user');
    }
    /**
     * Delete a feed. User must have the admin permission for the feed.
     *
     */
    feedDelete(arg) {
        return this.request('feed', 'delete', arg, 'user');
    }
    /**
     * Add an entry to a feed.
     *
     */
    feedEntryAdd(arg) {
        return this.request('feed', 'entry/add', arg, 'user');
    }
    /**
     * Add an entry to an ego feed.
     *
     */
    feedEntryAddEgo(arg) {
        return this.request('feed', 'entry/add_ego', arg, 'user');
    }
    /**
     * Add a post to a feed.
     *
     */
    feedEntryAddPost(arg) {
        return this.request('feed', 'entry/add_post', arg, 'user');
    }
    /**
     * Add a report entry to the reports feed.
     *
     */
    feedEntryAddReport(arg) {
        return this.request('feed', 'entry/add_report', arg, 'user');
    }
    /**
     * Add a video to a feed.
     *
     */
    feedEntryAddVideo(arg) {
        return this.request('feed', 'entry/add_video', arg, 'user');
    }
    /**
     * Add entry to the user's archive feed.
     *
     */
    feedEntryArchive(arg) {
        return this.request('feed', 'entry/archive', arg, 'user');
    }
    /**
     * Add entry to the user's archive feed. Augments entryArchive() with the
     * archive entry.
     *
     */
    feedEntryArchive2(arg) {
        return this.request('feed', 'entry/archive2', arg, 'user');
    }
    feedEntryContentGet(arg) {
        return this.request('feed', 'entry/content/get', arg, 'user_optional');
    }
    feedEntryContentRemove(arg) {
        return this.request('feed', 'entry/content/remove', arg, 'user');
    }
    /**
     * Deprecated by entryCpcUpdate().
     *
     */
    feedEntryContentSet(arg) {
        return this.request('feed', 'entry/content/set', arg, 'user');
    }
    feedEntryContentSetStatus(arg) {
        return this.request('feed', 'entry/content/set_status', arg, 'user');
    }
    /**
     * Sets CPC for a story. Despite the verb 'update', this will also set a new
     * CPC if none already exists.
     *
     */
    feedEntryCpcUpdate(arg) {
        return this.request('feed', 'entry/cpc/update', arg, 'user');
    }
    /**
     * The topics that will be (de-)emphasized based on user feedback.
     *
     */
    feedEntryFeedbackEffects(arg) {
        return this.request('feed', 'entry/feedback_effects', arg, 'user');
    }
    /**
     * Finds feeds with the input URL as an entry. Ignores debut feed entries.
     *
     */
    feedEntryFind(arg) {
        return this.request('feed', 'entry/find', arg, 'user');
    }
    /**
     * Return the latest feed entry along with the feed it's in.
     *
     */
    feedEntryGet(arg) {
        return this.request('feed', 'entry/get', arg, 'user_optional');
    }
    feedEntryGetAgentInfo(arg) {
        return this.request('feed', 'entry/get_agent_info', arg, 'user');
    }
    /**
     * Get the creators associated with an entry.
     *
     */
    feedEntryGetCreators(arg) {
        return this.request('feed', 'entry/get_creators', arg, 'user');
    }
    /**
     * Return the best URL for sharing a story. Makes a best effort attempt to
     * find a /e/ URL for sharing the entry. If not possible because the
     * containing feeds are private, falls back to the entry's direct URL.
     *
     */
    feedEntryGetUrlForSharing(arg) {
        return this.request('feed', 'entry/get_url_for_sharing', arg, 'user_optional');
    }
    /**
     * Adds a topic (hashtag) to an entry. If the hashtag is activated (whether
     * by the `force_active` flag or b/c the story has been ego-ed), the story
     * will also be added to the feed.
     *
     */
    feedEntryHashtagAdd(arg) {
        return this.request('feed', 'entry/hashtag/add', arg, 'user');
    }
    /**
     * Removes a topic (hashtag) from an entry.
     *
     */
    feedEntryHashtagRemove(arg) {
        return this.request('feed', 'entry/hashtag/remove', arg, 'user');
    }
    /**
     * Designates a topic (hashtag) as the natural topic for an entry.
     *
     */
    feedEntryHashtagSetNatural(arg) {
        return this.request('feed', 'entry/hashtag/set_natural', arg, 'user');
    }
    feedEntryHashtagUnsetNatural(arg) {
        return this.request('feed', 'entry/hashtag/unset_natural', arg, 'user');
    }
    /**
     * Iterate through entries in a feed.
     *
     */
    feedEntryIter(arg) {
        return this.request('feed', 'entry/iter', arg, 'user_optional');
    }
    feedEntryIterNext(arg) {
        return this.request('feed', 'entry/iter/next', arg, 'user_optional');
    }
    /**
     * When a user indicates they want to see fewer stories like this one.
     *
     */
    feedEntryLessLikeThis(arg) {
        return this.request('feed', 'entry/less_like_this', arg, 'user');
    }
    /**
     * Mark the progress the user has made through a story. The story must have
     * already been added to the user's visited feed.
     *
     */
    feedEntryMarkProgress(arg) {
        return this.request('feed', 'entry/mark_progress', arg, 'user');
    }
    /**
     * When a user indicates they want to see more stories like this one.
     *
     */
    feedEntryMoreLikeThis(arg) {
        return this.request('feed', 'entry/more_like_this', arg, 'user');
    }
    feedEntryPrune(arg) {
        return this.request('feed', 'entry/prune', arg, 'user');
    }
    /**
     * Return the latest information for a feed entry.
     *
     */
    feedEntryRefresh(arg) {
        return this.request('feed', 'entry/refresh', arg, 'user_optional');
    }
    /**
     * Remove an entry from a feed.
     *
     */
    feedEntryRemove(arg) {
        return this.request('feed', 'entry/remove', arg, 'user');
    }
    /**
     * Remove a via from an entry.
     *
     */
    feedEntryRemoveVia(arg) {
        return this.request('feed', 'entry/remove_via', arg, 'user');
    }
    /**
     * Lets a user send a private note to another user about a story they egoed.
     *
     */
    feedEntryReply(arg) {
        return this.request('feed', 'entry/reply', arg, 'user');
    }
    /**
     * Add a request to extract metadata for an entry.
     *
     */
    feedEntryReqExtractMetadata(arg) {
        return this.request('feed', 'entry/req_extract_metadata', arg, 'user');
    }
    /**
     * Report entries the user has seen.
     *
     */
    feedEntrySeen(arg) {
        return this.request('feed', 'entry/seen', arg, 'user');
    }
    feedEntrySetAgentInfo(arg) {
        return this.request('feed', 'entry/set_agent_info', arg, 'user');
    }
    /**
     * Overriding an entry's values will also apply to its strong ref (if strong
     * ref exists).
     *
     */
    feedEntrySetStaffOverrides(arg) {
        return this.request('feed', 'entry/set_staff_overrides', arg, 'user');
    }
    /**
     * Remove entry from the user's archive.
     *
     */
    feedEntryUnarchive(arg) {
        return this.request('feed', 'entry/unarchive', arg, 'user');
    }
    /**
     * Update a post.
     *
     */
    feedEntryUpdatePost(arg) {
        return this.request('feed', 'entry/update_post', arg, 'user');
    }
    /**
     * Follow a feed with public read permissions.
     *
     */
    feedFollow(arg) {
        return this.request('feed', 'follow', arg, 'user');
    }
    /**
     * Follow RSS feed with public read permissions.
     *
     */
    feedFollowRss(arg) {
        return this.request('feed', 'follow_rss', arg, 'user');
    }
    feedForwardCreate(arg) {
        return this.request('feed', 'forward/create', arg, 'user');
    }
    feedForwardDelete(arg) {
        return this.request('feed', 'forward/delete', arg, 'user');
    }
    /**
     * Return feeds that the queried feed flows into.
     *
     */
    feedForwardListDownstream(arg) {
        return this.request('feed', 'forward/list_downstream', arg, 'user');
    }
    /**
     * Return feeds that flow into the queried feed.
     *
     */
    feedForwardListUpstream(arg) {
        return this.request('feed', 'forward/list_upstream', arg, 'user');
    }
    /**
     * Get information about a feed.
     *
     */
    feedGet(arg) {
        return this.request('feed', 'get', arg, 'user_optional');
    }
    /**
     * Caller must be an admin of the feed or an agent.
     *
     */
    feedGetAcl(arg) {
        return this.request('feed', 'get_acl', arg, 'user');
    }
    feedGetAgentTopicTaxonomy(arg) {
        return this.request('feed', 'get_agent_topic_taxonomy', arg, 'user');
    }
    feedGetUpstreamRss(arg) {
        return this.request('feed', 'get_upstream_rss', arg, 'user');
    }
    feedGroupAddEmbed(arg) {
        return this.request('feed', 'group/add_embed', arg, 'user');
    }
    feedGroupAddFeed(arg) {
        return this.request('feed', 'group/add_feed', arg, 'user');
    }
    feedGroupAddSubGroup(arg) {
        return this.request('feed', 'group/add_sub_group', arg, 'user');
    }
    feedGroupCreate(arg) {
        return this.request('feed', 'group/create', arg, 'user');
    }
    /**
     * Get information for feed group. Edges that cause cycles are omitted as if
     * they do not exist.
     *
     */
    feedGroupGet(arg) {
        return this.request('feed', 'group/get', arg, 'user_optional');
    }
    feedGroupGetAppConfig(arg) {
        return this.request('feed', 'group/get_app_config', arg, 'user');
    }
    feedGroupRemoveEmbed(arg) {
        return this.request('feed', 'group/remove_embed', arg, 'user');
    }
    feedGroupRemoveFeed(arg) {
        return this.request('feed', 'group/remove_feed', arg, 'user');
    }
    feedGroupRemoveSubGroup(arg) {
        return this.request('feed', 'group/remove_sub_group', arg, 'user');
    }
    feedGroupSearch(arg) {
        return this.request('feed', 'group/search', arg, 'user');
    }
    /**
     * Iterate through feeds.
     *
     */
    feedIter(arg) {
        return this.request('feed', 'iter', arg, 'user_optional');
    }
    feedIterNext(arg) {
        return this.request('feed', 'iter/next', arg, 'user_optional');
    }
    feedMuteGet(arg) {
        return this.request('feed', 'mute/get', arg, 'user');
    }
    feedMuteSet(arg) {
        return this.request('feed', 'mute/set', arg, 'user');
    }
    feedNotificationPolicyGet(arg) {
        return this.request('feed', 'notification_policy/get', arg, 'user');
    }
    feedNotificationPolicySet(arg) {
        return this.request('feed', 'notification_policy/set', arg, 'user');
    }
    /**
     * Return onboarding topic feeds.
     *
     */
    feedOnboardingTopics(arg) {
        return this.request('feed', 'onboarding_topics', arg, 'user_optional');
    }
    /**
     * Return popular feeds.
     *
     */
    feedPopular(arg) {
        return this.request('feed', 'popular', arg, 'user_optional');
    }
    /**
     * Return popular feeds.
     *
     */
    feedPopular2(arg) {
        return this.request('feed', 'popular2', arg, 'user_optional');
    }
    /**
     * Return popular feeds.
     *
     */
    feedPopular3(arg) {
        return this.request('feed', 'popular3', arg, 'user_optional');
    }
    /**
     * Rename a feed. The caller must have admin access on the feed. However,
     * they do not need to be the publisher of the feed.
     *
     */
    feedRename(arg) {
        return this.request('feed', 'rename', arg, 'user');
    }
    /**
     * Caller must be an admin of the feed. Trying to remove read permissions
     * for a user that follows the feed will no-op.
     *
     */
    feedSetAcl(arg) {
        return this.request('feed', 'set_acl', arg, 'user');
    }
    feedSetDisplayMode(arg) {
        return this.request('feed', 'set_display_mode', arg, 'user');
    }
    feedSetImage(arg) {
        return this.request('feed', 'set_image', arg, 'user');
    }
    feedSetPrimaryDomain(arg) {
        return this.request('feed', 'set_primary_domain', arg, 'user');
    }
    /**
     * Changes the publisher of a feed to another user. The new publisher must
     * already be an admin of the feed.
     *
     */
    feedSetPublisher(arg) {
        return this.request('feed', 'set_publisher', arg, 'user');
    }
    feedSetStyle(arg) {
        return this.request('feed', 'set_style', arg, 'user');
    }
    /**
     * Unfollow a feed.
     *
     */
    feedUnfollow(arg) {
        return this.request('feed', 'unfollow', arg, 'user');
    }
    /**
     * Update the blurb for a feed.
     *
     */
    feedUpdateBlurb(arg) {
        return this.request('feed', 'update_blurb', arg, 'user');
    }
    /**
     * Update the default ordering for a feed.
     *
     */
    feedUpdateDefaultOrdering(arg) {
        return this.request('feed', 'update_default_ordering', arg, 'user');
    }
    /**
     * Update the public read permission for a feed.
     *
     */
    feedUpdatePermPublicRead(arg) {
        return this.request('feed', 'update_perm_public_read', arg, 'user');
    }
    /**
     * Create a shared feed with another user.
     *
     */
    inboxCreateSharedFeed(arg) {
        return this.request('inbox', 'create_shared_feed', arg, 'user');
    }
    inboxGetShareSuggestions(arg) {
        return this.request('inbox', 'get_share_suggestions', arg, 'user');
    }
    inboxGetUnread(arg) {
        return this.request('inbox', 'get_unread', arg, 'user');
    }
    /**
     * Deprecated by route:`get_unread`.
     *
     */
    inboxGetUnreadCount(arg) {
        return this.request('inbox', 'get_unread_count', arg, 'user');
    }
    /**
     * Attempts to smartly create an image deposit by looking at the RSS source
     * of a feed. Currently, for feeds based on YouTube channels, it deposits a
     * high-resolution image of the channel's YouTube profile image.
     *
     */
    medialibAutoDeposit(arg) {
        return this.request('medialib', 'auto_deposit', arg, 'user');
    }
    /**
     * For a client to report to the backend that the media upload is complete.
     *
     */
    medialibUploadDeposited(arg) {
        return this.request('medialib', 'upload/deposited', arg, 'user');
    }
    /**
     * Sets the stimulus target for a cpc video.
     *
     */
    medialibUploadSetCpcVideoTarget(arg) {
        return this.request('medialib', 'upload/set_cpc_video_target', arg, 'user');
    }
    /**
     * Creates a new media upload request. The caller is given a URL to deposit
     * the media via a PUT request. Afterwards, the caller must call
     * uploadDeposited().
     *
     */
    medialibUploadStart(arg) {
        return this.request('medialib', 'upload/start', arg, 'user');
    }
    /**
     * Return newsfeed entries.
     *
     */
    newsfeedEntryIter(arg) {
        return this.request('newsfeed', 'entry/iter', arg, 'user');
    }
    /**
     * Return more newsfeed entries beginning from cursor.
     *
     */
    newsfeedEntryIterNext(arg) {
        return this.request('newsfeed', 'entry/iter/next', arg, 'user_optional');
    }
    /**
     * Return another user's newsfeed.
     *
     */
    newsfeedEntryIterAsUser(arg) {
        return this.request('newsfeed', 'entry/iter_as_user', arg, 'user_optional');
    }
    /**
     * Return entries for default newsfeed.
     *
     */
    newsfeedEntryIterDefault(arg) {
        return this.request('newsfeed', 'entry/iter_default', arg, 'user_optional');
    }
    /**
     * Return entries for inbox.
     *
     */
    newsfeedEntryIterInbox(arg) {
        return this.request('newsfeed', 'entry/iter_inbox', arg, 'user');
    }
    /**
     * Deprecated
     *
     */
    newsfeedFilterGet(arg) {
        return this.request('newsfeed', 'filter/get', arg, 'user');
    }
    /**
     * Deprecated
     *
     */
    newsfeedFilterSet(arg) {
        return this.request('newsfeed', 'filter/set', arg, 'user');
    }
    pdataGetNewsfeedTopicStats(arg) {
        return this.request('pdata', 'get_newsfeed_topic_stats', arg, 'user');
    }
    pdataGetNewsfeedUserStats(arg) {
        return this.request('pdata', 'get_newsfeed_user_stats', arg, 'user');
    }
    pdataGetUserNewsfeedActivity(arg) {
        return this.request('pdata', 'get_user_newsfeed_activity', arg, 'user');
    }
    /**
     * Creates a new audience and associates it with a feed.
     *
     */
    prismAudienceCreate(arg) {
        return this.request('prism', 'audience/create', arg, 'user');
    }
    /**
     * Return information about the audience.
     *
     */
    prismAudienceGet(arg) {
        return this.request('prism', 'audience/get', arg, 'user_optional');
    }
    /**
     * Sets a feed's audience without joining the audience group. If feed
     * already has an audience set regardless of group membership, setting a new
     * audience will fail. The feed will first need to be removed from the
     * audience whether by removal from the group or by calling this route
     * without `audience_id` set.
     *
     */
    prismAudienceSet(arg) {
        return this.request('prism', 'audience/set', arg, 'user');
    }
    /**
     * Creates a new prism for a feed currently without an associated prism.
     *
     */
    prismCreate(arg) {
        return this.request('prism', 'create', arg, 'user');
    }
    /**
     * Return information about prism.
     *
     */
    prismGet(arg) {
        return this.request('prism', 'get', arg, 'user_optional');
    }
    /**
     * Orphans a prism from its parent feed. This frees the parent feed to
     * attach to another prism. Any members of the prism feed group will
     * continue to be members and will need to be manually updated.
     *
     */
    prismOrphan(arg) {
        return this.request('prism', 'orphan', arg, 'user');
    }
    /**
     * Sets a feed's prism without joining the prism group. If feed already has
     * prism set regardless of group membership, setting a new prism will fail.
     * The feed will first need to be removed from the prism whether by removal
     * from the group or by calling this route without `prism_id` set.
     *
     */
    prismSet(arg) {
        return this.request('prism', 'set', arg, 'user');
    }
    rssAddSource(arg) {
        return this.request('rss', 'add_source', arg, 'user');
    }
    rssAddUrlPrefix(arg) {
        return this.request('rss', 'add_url_prefix', arg, 'user');
    }
    /**
     * Queries the RSS feed and searches for CPC.
     *
     */
    rssGetCpcSample(arg) {
        return this.request('rss', 'get_cpc_sample', arg, 'user');
    }
    rssGetHomepageScoringDryRun(arg) {
        return this.request('rss', 'get_homepage_scoring_dry_run', arg, 'user');
    }
    rssGetRssInfo(arg) {
        return this.request('rss', 'get_rss_info', arg, 'user_optional');
    }
    rssGetSource(arg) {
        return this.request('rss', 'get_source', arg, 'user');
    }
    rssRemoveUrlPrefix(arg) {
        return this.request('rss', 'remove_url_prefix', arg, 'user');
    }
    rssScan(arg) {
        return this.request('rss', 'scan', arg, 'user');
    }
    rssSchedulePump(arg) {
        return this.request('rss', 'schedule_pump', arg, 'user');
    }
    /**
     * Sets how to use CPC from the RSS feed, if available.
     *
     */
    rssSetCpcPolicy(arg) {
        return this.request('rss', 'set_cpc_policy', arg, 'user');
    }
    /**
     * If enabling, the rss source is scheduled to be pumped immediately.
     *
     */
    rssSetEnable(arg) {
        return this.request('rss', 'set_enable', arg, 'user');
    }
    rssSetHomepageUrl(arg) {
        return this.request('rss', 'set_homepage_url', arg, 'user');
    }
    rssSetInjectMetadata(arg) {
        return this.request('rss', 'set_inject_metadata', arg, 'user');
    }
    rssSetPeriod(arg) {
        return this.request('rss', 'set_period', arg, 'user');
    }
    /**
     * If set, the CPC is extracted from the rss feed.
     *
     */
    rssSetUseCpc(arg) {
        return this.request('rss', 'set_use_cpc', arg, 'user');
    }
    rssSetUseHomepageForScoring(arg) {
        return this.request('rss', 'set_use_homepage_for_scoring', arg, 'user');
    }
    /**
     * Search for matching users and feeds based on search query. Does not
     * return feeds with no followers.
     *
     */
    searchOmni(arg) {
        return this.request('search', 'omni', arg, 'user_optional');
    }
    /**
     * Search for matching users who have registered as authors.
     *
     */
    searchQueryAuthors(arg) {
        return this.request('search', 'query_authors', arg, 'user_optional');
    }
    /**
     * Search for matching topics.
     *
     */
    searchQueryTopics(arg) {
        return this.request('search', 'query_topics', arg, 'user');
    }
    staffOpAutoCpcForUrl(arg) {
        return this.request('staff_op', 'auto_cpc_for_url', arg, 'user');
    }
    staffOpDomainPolicyGet(arg) {
        return this.request('staff_op', 'domain_policy/get', arg, 'user');
    }
    staffOpDomainPolicySet(arg) {
        return this.request('staff_op', 'domain_policy/set', arg, 'user');
    }
    staffOpGetTopicStats(arg) {
        return this.request('staff_op', 'get_topic_stats', arg, 'user');
    }
    /**
     * Forcibly assign the canonical url for a given url.
     *
     */
    staffOpStimulusAssignCanonical(arg) {
        return this.request('staff_op', 'stimulus/assign_canonical', arg, 'user');
    }
    /**
     * Assigns credit to a creator for a url/stimulus.
     *
     */
    staffOpStimulusCreditCreator(arg) {
        return this.request('staff_op', 'stimulus/credit_creator', arg, 'user');
    }
    /**
     * Assigns credit to a newly generated creator for a url/stimulus.
     *
     */
    staffOpStimulusCreditNewCreator(arg) {
        return this.request('staff_op', 'stimulus/credit_new_creator', arg, 'user');
    }
    /**
     * Assigns credit to a user for a url/stimulus.
     *
     */
    staffOpStimulusCreditUser(arg) {
        return this.request('staff_op', 'stimulus/credit_user', arg, 'user');
    }
    /**
     * Use this when a story cannot have its metadata extracted. This tends to
     * be a consequence of anti-web-crawling measures or the site being offline.
     *
     */
    staffOpStimulusInjectMetadata(arg) {
        return this.request('staff_op', 'stimulus/inject_metadata', arg, 'user');
    }
    staffOpStimulusRemoveInjectedMetadata(arg) {
        return this.request('staff_op', 'stimulus/remove_injected_metadata', arg, 'user');
    }
    /**
     * Remove canonical url assignment for a given url.
     *
     */
    staffOpStimulusUnassignCanonical(arg) {
        return this.request('staff_op', 'stimulus/unassign_canonical', arg, 'user');
    }
    staffOpUserGetFeedRecs(arg) {
        return this.request('staff_op', 'user/get_feed_recs', arg, 'user');
    }
    stimulusAppVideoRetranscode(arg) {
        return this.request('stimulus', 'app_video/retranscode', arg, 'user');
    }
    stimulusDiscussionAdd(arg) {
        return this.request('stimulus', 'discussion/add', arg, 'user');
    }
    stimulusDiscussionHide(arg) {
        return this.request('stimulus', 'discussion/hide', arg, 'user');
    }
    /**
     * Iterate through all entries that egoed a URL.
     *
     */
    stimulusEgoIter(arg) {
        return this.request('stimulus', 'ego/iter', arg, 'user_optional');
    }
    stimulusEgoIterNext(arg) {
        return this.request('stimulus', 'ego/iter/next', arg, 'user_optional');
    }
    stimulusEphemeralitySet(arg) {
        return this.request('stimulus', 'ephemerality/set', arg, 'user');
    }
    /**
     * Get information about a url.
     *
     */
    stimulusGet(arg) {
        return this.request('stimulus', 'get', arg, 'user_optional');
    }
    stimulusGetAppVideo(arg) {
        return this.request('stimulus', 'get_app_video', arg, 'user_optional');
    }
    /**
     * Get commentary around the web about a url.
     *
     */
    stimulusGetCommentary(arg) {
        return this.request('stimulus', 'get_commentary', arg, 'user_optional');
    }
    stimulusGetMainEntryBatch(arg) {
        return this.request('stimulus', 'get_main_entry_batch', arg, 'user_optional');
    }
    /**
     * Get RSS feeds for a url.
     *
     */
    stimulusGetRss(arg) {
        return this.request('stimulus', 'get_rss', arg, 'user_optional');
    }
    storyAiApplyAiRecs(arg) {
        return this.request('story_ai', 'apply_ai_recs', arg, 'user');
    }
    /**
     * Route name is a misnomer as its capabilities have expanded.
     *
     */
    storyAiGetRelatedWikis(arg) {
        return this.request('story_ai', 'get_related_wikis', arg, 'user');
    }
    storyAiPrompt(arg) {
        return this.request('story_ai', 'prompt', arg, 'user');
    }
    storyAiPromptCompareEntry(arg) {
        return this.request('story_ai', 'prompt_compare_entry', arg, 'user');
    }
    storyAiRuleBlockWiki(arg) {
        return this.request('story_ai', 'rule/block_wiki', arg, 'user');
    }
    storyAiRuleGetDoubleCheckPrompt(arg) {
        return this.request('story_ai', 'rule/get_double_check_prompt', arg, 'user');
    }
    storyAiRuleSetDoubleCheckPrompt(arg) {
        return this.request('story_ai', 'rule/set_double_check_prompt', arg, 'user');
    }
    storyAiSummaryGenerate(arg) {
        return this.request('story_ai', 'summary/generate', arg, 'user');
    }
    storyAiSummaryGet(arg) {
        return this.request('story_ai', 'summary/get', arg, 'user');
    }
    storyAiSummaryRemove(arg) {
        return this.request('story_ai', 'summary/remove', arg, 'user');
    }
    /**
     * Add a new comment or reply to a thread.
     *
     */
    threadCommentAdd(arg) {
        return this.request('thread', 'comment/add', arg, 'user');
    }
    /**
     * Delete a comment. While the comment's body will no longer be returned,
     * the metadata of the comment remains as a tombstone.
     *
     */
    threadCommentDelete(arg) {
        return this.request('thread', 'comment/delete', arg, 'user');
    }
    /**
     * Down-vote a comment.
     *
     */
    threadCommentDownvote(arg) {
        return this.request('thread', 'comment/downvote', arg, 'user');
    }
    /**
     * Retract an earlier vote on a comment.
     *
     */
    threadCommentUnvote(arg) {
        return this.request('thread', 'comment/unvote', arg, 'user');
    }
    /**
     * Up-vote a comment.
     *
     */
    threadCommentUpvote(arg) {
        return this.request('thread', 'comment/upvote', arg, 'user');
    }
    threadGetBreadcrumb(arg) {
        return this.request('thread', 'get_breadcrumb', arg, 'user');
    }
    /**
     * Returns the specified comment and its descendants.
     *
     */
    threadGetReplies(arg) {
        return this.request('thread', 'get_replies', arg, 'user_optional');
    }
    /**
     * Returns siblings (and their descendants) of a specified comment.
     *
     */
    threadGetSiblings(arg) {
        return this.request('thread', 'get_siblings', arg, 'user_optional');
    }
    /**
     * Returns comments associated with a thread. Note that each top-level
     * comment is its own tree (has no parent). It's likely that not all of the
     * comments will be returned. To gather the rest, use getReplies() and
     * getSiblings().
     *
     */
    threadGetTree(arg) {
        return this.request('thread', 'get_tree', arg, 'user_optional');
    }
    threadSeen(arg) {
        return this.request('thread', 'seen', arg, 'user');
    }
    topicAssociate(arg) {
        return this.request('topic', 'associate', arg, 'user');
    }
    topicComputeRelatedToFeed(arg) {
        return this.request('topic', 'compute_related_to_feed', arg, 'user');
    }
    /**
     * Creates a new feed that's associated with the wiki object. If the wiki
     * object is already assigned to a feed, returns the existing feed.
     *
     */
    topicCreateFromWiki(arg) {
        return this.request('topic', 'create_from_wiki', arg, 'user');
    }
    topicDisassociate(arg) {
        return this.request('topic', 'disassociate', arg, 'user');
    }
    topicGetCotopics(arg) {
        return this.request('topic', 'get_cotopics', arg, 'user');
    }
    topicGetRelatedToFeed(arg) {
        return this.request('topic', 'get_related_to_feed', arg, 'user');
    }
    topicGetWikiUrl(arg) {
        return this.request('topic', 'get_wiki_url', arg, 'user_optional');
    }
    /**
     * Get topic-based recommendations.
     *
     */
    topicRecommend(arg) {
        return this.request('topic', 'recommend', arg, 'user');
    }
    /**
     * Get information about a user.
     *
     */
    userGet(arg) {
        return this.request('user', 'get', arg, 'user_optional');
    }
    userGetRecentEmails(arg) {
        return this.request('user', 'get_recent_emails', arg, 'user');
    }
    userGetRecentPushNotifs(arg) {
        return this.request('user', 'get_recent_push_notifs', arg, 'user');
    }
    userGetUserInfoForAgent(arg) {
        return this.request('user', 'get_user_info_for_agent', arg, 'user');
    }
    userGiveFeedback(arg) {
        return this.request('user', 'give_feedback', arg, 'user');
    }
    userMe(arg) {
        return this.request('user', 'me', arg, 'user');
    }
    /**
     * Identical to me() since it was migrated from its own return struct to a
     * patched struct.
     *
     */
    userMeInternal(arg) {
        return this.request('user', 'me_internal', arg, 'user');
    }
    userMeInternalWeb(arg) {
        return this.request('user', 'me_internal_web', arg, 'user_optional');
    }
    userSendVerificationEmail(arg) {
        return this.request('user', 'send_verification_email', arg, 'user');
    }
    userUpdateBio(arg) {
        return this.request('user', 'update_bio', arg, 'user');
    }
    userUpdateName(arg) {
        return this.request('user', 'update_name', arg, 'user');
    }
    userUpdateWebsite(arg) {
        return this.request('user', 'update_website', arg, 'user');
    }
}
exports.BaseClient = BaseClient;
