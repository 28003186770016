import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { m } from 'framer-motion';
import React from 'react';

import { ButtonLabel, ExploreActionButton, FeedActionButton } from './ActionButton';
import { FeedEntryOps } from './hooks/useFeedEntryOps';
import EditIcon from './icon/EditIcon';
import EgoSlice from './icon/EgoSlice';
import ExploreIcon from './icon/ExploreIcon';
import PruneIcon from './icon/PruneIcon';
import RefreshIcon from './icon/RefreshIcon';
import SaveIcon from './icon/SaveIcon';
import SendToIcon from './icon/SendToIcon';
import TrashIcon from './icon/TrashIcon';
import CmdInput from './lib/CmdInput';

const ScaleOnHover = (props: { children: React.ReactNode }) => (
  <m.div variants={{ hover: { scale: 1.075 } }}>{props.children}</m.div>
);

const RotateOnHover = (props: { children: React.ReactNode }) => (
  <m.div variants={{ hover: { rotateZ: -45 } }}>{props.children}</m.div>
);

const FeedEntryActions = (props: {
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  actions: FeedEntryOps;
  hideArchive?: boolean;
  vertical?: boolean;
  agentMode: boolean;
}) => {
  return (
    <div className={clsx('tw-flex tw-grow tw-items-center', props.vertical ? 'tw-flex-col tw-gap-y-2' : 'tw-gap-x-2')}>
      {props.feed.type['.tag'] !== 'notif' && props.feed.type['.tag'] !== 'rec_for_you' ? (
        <>
          {props.actions.ego ? (
            <FeedActionButton title="Give Ego (1)" onClick={props.actions.ego}>
              <GiveEgoLabel label={false} entry={props.entry} agentMode={props.agentMode} />
            </FeedActionButton>
          ) : null}
          {props.actions.sendTo ? (
            <FeedActionButton title="Send To (2)" onClick={props.actions.sendTo}>
              <SendToLabel label={false} entry={props.entry} agentMode={props.agentMode} />
            </FeedActionButton>
          ) : null}
          {props.actions.explore ? (
            <FeedActionButton
              title="Metadata (I)"
              onClick={e => {
                if (e.ctrlKey || e.metaKey) {
                  e.stopPropagation();
                  window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank');
                  return;
                }
                props.actions.explore();
              }}
              onAuxClick={() => window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank')}
            >
              <ExploreLabel />
            </FeedActionButton>
          ) : null}
          {props.agentMode && props.actions.remove ? (
            <FeedActionButton title="Remove" onClick={props.actions.remove}>
              <RemoveLabel label={false} />
            </FeedActionButton>
          ) : null}
          {!props.entry.for_viewer.s4l && props.actions.saveForLater ? (
            <FeedActionButton title="Save (3)" onClick={props.actions.saveForLater}>
              <SaveLabel label={false} entry={props.entry} agentMode={props.agentMode} highlight={false} />
            </FeedActionButton>
          ) : props.actions.saveToLibrary ? (
            <FeedActionButton title="Save (3)" onClick={props.actions.saveToLibrary}>
              <SaveLabel
                label={false}
                entry={props.entry}
                agentMode={props.agentMode}
                highlight={!!props.entry.for_viewer.s4l}
              />
            </FeedActionButton>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export const FeedEntryCompactActions = (props: { entry: api.feed.IFeedEntryReference; actions: FeedEntryOps }) => {
  const btnclassName = clsx(
    'tw-text-muted hover:tw-text-body-light dark:hover:tw-text-body-dark',
    'tw-hidden sm:tw-flex tw-flex-1 tw-items-center tw-justify-center',
    'hover:tw-bg-purple-300 dark:hover:tw-bg-purple-700',
  );
  return (
    <div className="tw-flex tw-h-full tw-mr-5">
      <m.div
        role="button"
        title="Give Ego (1)"
        whileHover="hover"
        onClick={e => {
          if (e.ctrlKey || e.metaKey) {
            e.stopPropagation();
            window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank');
            return;
          }
          e.stopPropagation();
          if (props.actions.ego) {
            props.actions.ego();
          }
        }}
        className={clsx(
          btnclassName,
          'tw-border-l tw-border-solid tw-border-y-0 tw-border-r-0 tw-border-layout-line-light dark:tw-border-layout-line-dark',
        )}
      >
        <RotateOnHover>
          <EgoSlice size="1.1rem" />
        </RotateOnHover>
      </m.div>
      <m.div
        role="button"
        title="Send To (2)"
        whileHover="hover"
        onClick={e => {
          if (e.ctrlKey || e.metaKey) {
            e.stopPropagation();
            window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank');
            return;
          }
          e.stopPropagation();
          if (props.actions.sendTo) {
            props.actions.sendTo();
          }
        }}
        className={btnclassName}
      >
        <ScaleOnHover>
          <SendToIcon size="1.1rem" />
        </ScaleOnHover>
      </m.div>
      <m.div
        role="button"
        title="Metadata (I)"
        whileHover="hover"
        onClick={e => {
          if (e.ctrlKey || e.metaKey) {
            e.stopPropagation();
            window.open(cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id), '_blank');
            return;
          }
          e.stopPropagation();
          props.actions.explore();
        }}
        className={btnclassName}
      >
        <ScaleOnHover>
          <ExploreIcon size="1.1rem" />
        </ScaleOnHover>
      </m.div>
      {!props.entry.for_viewer.s4l && props.actions.saveForLater ? (
        <m.div
          role="button"
          title="Save (3)"
          whileHover="hover"
          onClick={e => {
            if (props.actions.saveForLater) {
              e.stopPropagation();
              props.actions.saveForLater();
            }
          }}
          className={btnclassName}
        >
          <ScaleOnHover>
            <SaveIcon size="1.1rem" />
          </ScaleOnHover>
        </m.div>
      ) : props.actions.saveToLibrary ? (
        <m.div
          role="button"
          title="Save (3)"
          whileHover="hover"
          onClick={e => {
            if (props.actions.saveToLibrary) {
              e.stopPropagation();
              props.actions.saveToLibrary();
            }
          }}
          className={btnclassName}
        >
          <ScaleOnHover>
            <SaveIcon size="1.1rem" className="tw-text-perpul-light dark:tw-text-perpul-dark" />
          </ScaleOnHover>
        </m.div>
      ) : null}
    </div>
  );
};

export const FeedEntryExploreActions = (props: {
  entry: api.feed.IFeedEntryReference;
  actions: FeedEntryOps;
  agentMode: boolean;
}) => {
  return (
    <div>
      <div className="tw-flex tw-gap-x-2">
        {!props.actions.opensToExplore ? (
          <ExploreActionButton primary title="Open (o)" onClick={() => props.actions.open()}>
            <ButtonLabel text="Open" xsHide={false} />
          </ExploreActionButton>
        ) : null}
        {props.actions.ego ? (
          <ExploreActionButton title="Give Ego (1)" onClick={props.actions.ego}>
            <GiveEgoLabel label entry={props.entry} agentMode={props.agentMode} />
          </ExploreActionButton>
        ) : null}
        {props.actions.sendTo ? (
          <ExploreActionButton title="Send To (2)" onClick={props.actions.sendTo}>
            <SendToLabel label entry={props.entry} agentMode={props.agentMode} />
          </ExploreActionButton>
        ) : null}
        {!props.entry.for_viewer.s4l && props.actions.saveForLater ? (
          <ExploreActionButton title="Save (3)" onClick={props.actions.saveForLater}>
            <SaveLabel label entry={props.entry} agentMode={props.agentMode} highlight={false} />
          </ExploreActionButton>
        ) : props.actions.saveToLibrary ? (
          <ExploreActionButton title="Save (3)" onClick={props.actions.saveToLibrary}>
            <SaveLabel label entry={props.entry} agentMode={props.agentMode} highlight={!!props.entry.for_viewer.s4l} />
          </ExploreActionButton>
        ) : null}
        {props.actions.remove ? (
          <ExploreActionButton title="Remove" onClick={props.actions.remove}>
            <RemoveLabel label />
          </ExploreActionButton>
        ) : null}
        {props.actions.prune ? (
          <ExploreActionButton title="Prune" onClick={props.actions.prune}>
            <ScaleOnHover>
              <PruneIcon size="0.9rem" />
            </ScaleOnHover>
            <ButtonLabel text="Prune" xsHide />
          </ExploreActionButton>
        ) : null}
      </div>
      {props.actions.editPost || (props.actions.extractMetadata && props.agentMode) ? (
        <div className="tw-flex tw-mt-2 tw-gap-x-2">
          {props.actions.editPost ? (
            <ExploreActionButton title="Edit Post" onClick={props.actions.editPost}>
              <ScaleOnHover>
                <EditIcon size="0.85rem" />
              </ScaleOnHover>
              <ButtonLabel text="Edit Post" xsHide />
            </ExploreActionButton>
          ) : null}
          {props.actions.extractMetadata ? (
            <ExploreActionButton title="Extract metadata" onClick={props.actions.extractMetadata}>
              <ScaleOnHover>
                <RefreshIcon size="1rem" offsetUp />
              </ScaleOnHover>
              <ButtonLabel text="Metadata" xsHide />
            </ExploreActionButton>
          ) : null}
          {props.agentMode ? (
            <div className="tw-flex tw-items-center tw-text-sm">
              <CmdInput cmd="Shift + R" />
              <ButtonLabel text="Refresh" xsHide={false} />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const ExploreLabel = () => (
  <ScaleOnHover>
    <ExploreIcon size="0.95rem" />
  </ScaleOnHover>
);

const GiveEgoLabel = (props: { label: boolean; entry: api.feed.IFeedEntryReference; agentMode: boolean }) => (
  <>
    <RotateOnHover>
      <EgoSlice size={props.label ? 16 : 15} />
    </RotateOnHover>
    {props.entry.feed_ref_counts && props.entry.feed_ref_counts.ego > 0 ? (
      <span className="tw-ml-1 tw-text-xs">{cfe.Formatter.abbreviateNumber(props.entry.feed_ref_counts.ego)}</span>
    ) : props.label ? (
      <ButtonLabel text="Ego" xsHide />
    ) : null}
  </>
);

const SendToLabel = (props: { label: boolean; entry: api.feed.IFeedEntryReference; agentMode: boolean }) => (
  <>
    <ScaleOnHover>
      <SendToIcon size="0.95rem" offsetUp />
    </ScaleOnHover>
    {props.agentMode && props.entry.feed_ref_counts && props.entry.feed_ref_counts.shares > 0 ? (
      <span className="tw-ml-1 tw-text-xs">{cfe.Formatter.abbreviateNumber(props.entry.feed_ref_counts.shares)}</span>
    ) : props.label ? (
      <ButtonLabel text="Send" xsHide />
    ) : null}
  </>
);

const RemoveLabel = (props: { label: boolean }) => (
  <>
    <ScaleOnHover>
      <TrashIcon size="0.9rem" />
    </ScaleOnHover>
    {props.label ? <ButtonLabel text="Remove" xsHide /> : null}
  </>
);

const SaveLabel = (props: {
  label: boolean;
  entry: api.feed.IFeedEntryReference;
  highlight: boolean;
  agentMode: boolean;
}) => (
  <>
    <ScaleOnHover>
      <SaveIcon
        size="0.9rem"
        className={props.highlight ? 'tw-text-perpul-light dark:tw-text-perpul-dark' : undefined}
      />
    </ScaleOnHover>
    {props.agentMode && props.entry.feed_ref_counts && props.entry.feed_ref_counts.saves > 0 ? (
      <span className="tw-ml-1 tw-text-xs">{cfe.Formatter.abbreviateNumber(props.entry.feed_ref_counts.saves)}</span>
    ) : props.label ? (
      <ButtonLabel text="Save" xsHide />
    ) : null}
  </>
);

export default FeedEntryActions;
