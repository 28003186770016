import * as api from 'ego-sdk-js';
import React from 'react';

import { CpcSliderContext } from './FeedEntryCpcViewer';
import YouTubeIcon from './icon/YouTubeIcon';
import SectionHeaderTab from './SectionHeaderTab';
import YouTubeCustomPlayer from './YouTubeCustomPlayer';

const YouTubeEmbed = React.memo(
  (props: {
    ytVideoId: string;
    duration?: number;
    portrait?: boolean;
    gutterClassName?: string;
    maxHeightShort?: boolean;
    parentSlideIndex?: number;
    mainEntry?: api.feed.IFeedEntryPair;
  }) => {
    const cpcSliderContext = React.useContext(CpcSliderContext);
    return (
      <div className="tw-flex tw-flex-col tw-aligns-center">
        <SectionHeaderTab
          title="YouTube Video"
          titleSuffix={<YouTubeIcon colorOverride="white" size="30px" className="tw-ml-2" />}
          muted
          noBottomMargin
        />
        <YouTubeCustomPlayer
          ytVideoId={props.ytVideoId}
          recordStoryOpen={() => null}
          portrait={props.portrait}
          forceNoAutoPlay
          gutterClassName={props.gutterClassName}
          focusPlay={cpcSliderContext.slideIndex === props.parentSlideIndex}
          onEnd={cpcSliderContext.nextSlide}
          maxHeightShort={props.maxHeightShort}
          mainEntry={props.mainEntry}
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.ytVideoId === nextProps.ytVideoId &&
    prevProps.duration === nextProps.duration &&
    prevProps.portrait === nextProps.portrait &&
    prevProps.gutterClassName === nextProps.gutterClassName &&
    prevProps.maxHeightShort === nextProps.maxHeightShort &&
    prevProps.parentSlideIndex === nextProps.parentSlideIndex &&
    prevProps.mainEntry === nextProps.mainEntry,
);

export default YouTubeEmbed;
